<template>
  <div class="icp">{{`© ${year} ${author} ` }}<a href="http://beian.miit.gov.cn/" target="_blank">{{record}}</a></div>
</template>

<script>
  export default {
    name: "",
    data() {
      return {
        year: new Date().getFullYear(),
        author: "四川聚欢鑫网络科技有限公司 版权所有",
        record: "蜀ICP备2024055140号-2"
      }
    }
  }
</script>



<style>
.icp {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px 0;
  width: 100%;
  height: 36px;
  white-space: pre;
  text-align: center;
  color: gray;
  z-index: 1000;
}
.icp > a {
  color: gray;
  text-decoration: none;
}
.icp > a:hover {
  color: aqua;
  text-decoration: none;
}
</style>
