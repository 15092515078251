<template>
  <div>
    <particles-bg type="random" :bg="true" />
    <div class="login-container" id="home">
      <div class="form_wrapper">
        <el-form
          autoComplete="on"
          :model="loginForm"
          :rules="loginRules"
          ref="loginForm"
          label-position="right"
          label-width="80px"
          class="card-box login-form"
        >
          <h1 class="title">呼客宝</h1>
          <el-form-item prop="email" label="账号">
            <el-input
              name="phone"
              type="text"
              v-model="loginForm.phone"
              autoComplete="off"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input
              name="userPwd"
              type="password"
              @keyup.enter.native="handleLogin"
              v-model="loginForm.userPwd"
              autoComplete="off"
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <div style="display: flex; justify-content: center">
            <el-button
              round
              type="primary"
              :loading="loading"
              style="width: 200px"
              @click.native.prevent="handleLogin"
            >
              登录
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 配置备案号 -->
    <CopyrightIcp></CopyrightIcp>
  </div>
</template>

<script>
import { ParticlesBg } from "particles-bg-vue";
import CopyrightIcp from "../components/Copyright/copyright-icp.vue";

export default {
  name: "login",
  components: {
    CopyrightIcp,
    ParticlesBg,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能小于6位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        phone: "",
        userPwd: "",
      },
      loginRules: {
        phone: [{ required: true, trigger: "blur" }],
        userPwd: [{ required: true, trigger: "blur", validator: validatePass }],
      },
      loading: false,
    };
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("Login", this.loginForm)
            .then((data) => {
              this.$router.push({ path: "/" });
            })
            .catch(() => {
              console.log("catch");
              this.loading = false;
            })
            .finally(() => {
              console.log("finally");
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  .form_wrapper {
    .title {
      font-size: 30px;
      font-weight: 500;
      color: rgba($color: #000, $alpha: 0.7);
      text-align: center;
      margin-left: 20px;
      margin-bottom: 30px;
    }

    .login-form {
      /*border: 1px solid red;*/

      background: rgba($color: #fff, $alpha: 1);
      position: absolute;
      left: 0;
      right: 0;
      z-index: 99;
      width: 400px;
      padding: 35px 35px 15px 35px;
      margin: 0 auto;
      top: calc(50% - 50px);
      transform: translate(0, -50%);
      border-radius: 10px;
    }

    .el-form-item {
      /*border: 1px solid rgba(255, 255, 255, 0.1);*/
      /*background: rgba(0, 0, 0, 0.1);*/
      border-radius: 5px;
      color: #454545;
    }

    .forget-pwd {
      color: #fff;
    }
  }

  .wrapper_bottom {
    position: absolute;
    margin-left: 43%;
    margin-top: 50px;
    color: #409eff;

    input {
      border: none;
      background: none;
      margin: 0 30px;
      outline: none;
    }
  }
}
</style>
